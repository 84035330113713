export const COUNTRIES = {
    AD: "AD",
    AE: "AE",
    AF: "AF",
    AG: "AG",
    AI: "AI",
    AL: "AL",
    AM: "AM",
    AN: "AN",
    AO: "AO",
    AQ: "AQ",
    AR: "AR",
    AS: "AS",
    AT: "AT",
    AU: "AU",
    AW: "AW",
    AX: "AX",
    AZ: "AZ",
    BA: "BA",
    BB: "BB",
    BD: "BD",
    BE: "BE",
    BF: "BF",
    BG: "BG",
    BH: "BH",
    BI: "BI",
    BJ: "BJ",
    BM: "BM",
    BN: "BN",
    BO: "BO",
    BR: "BR",
    BS: "BS",
    BT: "BT",
    BV: "BV",
    BW: "BW",
    BY: "BY",
    BZ: "BZ",
    CA: "CA",
    CC: "CC",
    CD: "CD",
    CF: "CF",
    CG: "CG",
    CH: "CH",
    CI: "CI",
    CK: "CK",
    CL: "CL",
    CM: "CM",
    CN: "CN",
    CO: "CO",
    CR: "CR",
    CS: "CS",
    CU: "CU",
    CV: "CV",
    CX: "CX",
    CY: "CY",
    CZ: "CZ",
    DE: "DE",
    DJ: "DJ",
    DK: "DK",
    DM: "DM",
    DO: "DO",
    DZ: "DZ",
    EC: "EC",
    EE: "EE",
    EG: "EG",
    EH: "EH",
    ER: "ER",
    ES: "ES",
    ET: "ET",
    FI: "FI",
    FJ: "FJ",
    FK: "FK",
    FM: "FM",
    FO: "FO",
    FR: "FR",
    GA: "GA",
    GB: "GB",
    GD: "GD",
    GE: "GE",
    GF: "GF",
    GG: "GG",
    GH: "GH",
    GI: "GI",
    GL: "GL",
    GM: "GM",
    GN: "GN",
    GP: "GP",
    GQ: "GQ",
    GR: "GR",
    GS: "GS",
    GT: "GT",
    GU: "GU",
    GW: "GW",
    GY: "GY",
    HK: "HK",
    HM: "HM",
    HN: "HN",
    HR: "HR",
    HT: "HT",
    HU: "HU",
    ID: "ID",
    IE: "IE",
    IL: "IL",
    IM: "IM",
    IN: "IN",
    IO: "IO",
    IQ: "IQ",
    IR: "IR",
    IS: "IS",
    IT: "IT",
    JE: "JE",
    JM: "JM",
    JO: "JO",
    JP: "JP",
    KE: "KE",
    KG: "KG",
    KH: "KH",
    KI: "KI",
    KM: "KM",
    KN: "KN",
    KP: "KP",
    KR: "KR",
    KW: "KW",
    KY: "KY",
    KZ: "KZ",
    LA: "LA",
    LB: "LB",
    LC: "LC",
    LI: "LI",
    LK: "LK",
    LR: "LR",
    LS: "LS",
    LT: "LT",
    LU: "LU",
    LV: "LV",
    LY: "LY",
    MA: "MA",
    MC: "MC",
    MD: "MD",
    MG: "MG",
    MH: "MH",
    MK: "MK",
    ML: "ML",
    MM: "MM",
    MN: "MN",
    MO: "MO",
    MP: "MP",
    MQ: "MQ",
    MR: "MR",
    MS: "MS",
    MT: "MT",
    MU: "MU",
    MV: "MV",
    MW: "MW",
    MX: "MX",
    MY: "MY",
    MZ: "MZ",
    NA: "NA",
    NC: "NC",
    NE: "NE",
    NF: "NF",
    NG: "NG",
    NI: "NI",
    NL: "NL",
    NO: "NO",
    NP: "NP",
    NR: "NR",
    NU: "NU",
    NZ: "NZ",
    OM: "OM",
    PA: "PA",
    PE: "PE",
    PF: "PF",
    PG: "PG",
    PH: "PH",
    PK: "PK",
    PL: "PL",
    PM: "PM",
    PN: "PN",
    PR: "PR",
    PS: "PS",
    PT: "PT",
    PW: "PW",
    PY: "PY",
    QA: "QA",
    RE: "RE",
    RO: "RO",
    RU: "RU",
    RW: "RW",
    SA: "SA",
    SB: "SB",
    SC: "SC",
    SD: "SD",
    SE: "SE",
    SG: "SG",
    SH: "SH",
    SI: "SI",
    SJ: "SJ",
    SK: "SK",
    SL: "SL",
    SM: "SM",
    SN: "SN",
    SO: "SO",
    SR: "SR",
    ST: "ST",
    SV: "SV",
    SY: "SY",
    SZ: "SZ",
    TC: "TC",
    TD: "TD",
    TF: "TF",
    TG: "TG",
    TH: "TH",
    TJ: "TJ",
    TK: "TK",
    TL: "TL",
    TM: "TM",
    TN: "TN",
    TO: "TO",
    TR: "TR",
    TT: "TT",
    TV: "TV",
    TW: "TW",
    TZ: "TZ",
    UA: "UA",
    UG: "UG",
    UM: "UM",
    US: "US",
    UY: "UY",
    UZ: "UZ",
    VA: "VA",
    VC: "VC",
    VE: "VE",
    VG: "VG",
    VI: "VI",
    VN: "VN",
    VU: "VU",
    WF: "WF",
    WS: "WS",
    YE: "YE",
    YT: "YT",
    ZA: "ZA",
    ZM: "ZM",
    ZW: "ZW",
};
export const COUNTRY_VALUES = [
    COUNTRIES.AD,
    COUNTRIES.AE,
    COUNTRIES.AF,
    COUNTRIES.AG,
    COUNTRIES.AI,
    COUNTRIES.AL,
    COUNTRIES.AM,
    COUNTRIES.AN,
    COUNTRIES.AO,
    COUNTRIES.AQ,
    COUNTRIES.AR,
    COUNTRIES.AS,
    COUNTRIES.AT,
    COUNTRIES.AU,
    COUNTRIES.AW,
    COUNTRIES.AX,
    COUNTRIES.AZ,
    COUNTRIES.BA,
    COUNTRIES.BB,
    COUNTRIES.BD,
    COUNTRIES.BE,
    COUNTRIES.BF,
    COUNTRIES.BG,
    COUNTRIES.BH,
    COUNTRIES.BI,
    COUNTRIES.BJ,
    COUNTRIES.BM,
    COUNTRIES.BN,
    COUNTRIES.BO,
    COUNTRIES.BR,
    COUNTRIES.BS,
    COUNTRIES.BT,
    COUNTRIES.BV,
    COUNTRIES.BW,
    COUNTRIES.BY,
    COUNTRIES.BZ,
    COUNTRIES.CA,
    COUNTRIES.CC,
    COUNTRIES.CD,
    COUNTRIES.CF,
    COUNTRIES.CG,
    COUNTRIES.CH,
    COUNTRIES.CI,
    COUNTRIES.CK,
    COUNTRIES.CL,
    COUNTRIES.CM,
    COUNTRIES.CN,
    COUNTRIES.CO,
    COUNTRIES.CR,
    COUNTRIES.CS,
    COUNTRIES.CU,
    COUNTRIES.CV,
    COUNTRIES.CX,
    COUNTRIES.CY,
    COUNTRIES.CZ,
    COUNTRIES.DE,
    COUNTRIES.DJ,
    COUNTRIES.DK,
    COUNTRIES.DM,
    COUNTRIES.DO,
    COUNTRIES.DZ,
    COUNTRIES.EC,
    COUNTRIES.EE,
    COUNTRIES.EG,
    COUNTRIES.EH,
    COUNTRIES.ER,
    COUNTRIES.ES,
    COUNTRIES.ET,
    COUNTRIES.FI,
    COUNTRIES.FJ,
    COUNTRIES.FK,
    COUNTRIES.FM,
    COUNTRIES.FO,
    COUNTRIES.FR,
    COUNTRIES.GA,
    COUNTRIES.GB,
    COUNTRIES.GD,
    COUNTRIES.GE,
    COUNTRIES.GF,
    COUNTRIES.GG,
    COUNTRIES.GH,
    COUNTRIES.GI,
    COUNTRIES.GL,
    COUNTRIES.GM,
    COUNTRIES.GN,
    COUNTRIES.GP,
    COUNTRIES.GQ,
    COUNTRIES.GR,
    COUNTRIES.GS,
    COUNTRIES.GT,
    COUNTRIES.GU,
    COUNTRIES.GW,
    COUNTRIES.GY,
    COUNTRIES.HK,
    COUNTRIES.HM,
    COUNTRIES.HN,
    COUNTRIES.HR,
    COUNTRIES.HT,
    COUNTRIES.HU,
    COUNTRIES.ID,
    COUNTRIES.IE,
    COUNTRIES.IL,
    COUNTRIES.IM,
    COUNTRIES.IN,
    COUNTRIES.IO,
    COUNTRIES.IQ,
    COUNTRIES.IR,
    COUNTRIES.IS,
    COUNTRIES.IT,
    COUNTRIES.JE,
    COUNTRIES.JM,
    COUNTRIES.JO,
    COUNTRIES.JP,
    COUNTRIES.KE,
    COUNTRIES.KG,
    COUNTRIES.KH,
    COUNTRIES.KI,
    COUNTRIES.KM,
    COUNTRIES.KN,
    COUNTRIES.KP,
    COUNTRIES.KR,
    COUNTRIES.KW,
    COUNTRIES.KY,
    COUNTRIES.KZ,
    COUNTRIES.LA,
    COUNTRIES.LB,
    COUNTRIES.LC,
    COUNTRIES.LI,
    COUNTRIES.LK,
    COUNTRIES.LR,
    COUNTRIES.LS,
    COUNTRIES.LT,
    COUNTRIES.LU,
    COUNTRIES.LV,
    COUNTRIES.LY,
    COUNTRIES.MA,
    COUNTRIES.MC,
    COUNTRIES.MD,
    COUNTRIES.MG,
    COUNTRIES.MH,
    COUNTRIES.MK,
    COUNTRIES.ML,
    COUNTRIES.MM,
    COUNTRIES.MN,
    COUNTRIES.MO,
    COUNTRIES.MP,
    COUNTRIES.MQ,
    COUNTRIES.MR,
    COUNTRIES.MS,
    COUNTRIES.MT,
    COUNTRIES.MU,
    COUNTRIES.MV,
    COUNTRIES.MW,
    COUNTRIES.MX,
    COUNTRIES.MY,
    COUNTRIES.MZ,
    COUNTRIES.NA,
    COUNTRIES.NC,
    COUNTRIES.NE,
    COUNTRIES.NF,
    COUNTRIES.NG,
    COUNTRIES.NI,
    COUNTRIES.NL,
    COUNTRIES.NO,
    COUNTRIES.NP,
    COUNTRIES.NR,
    COUNTRIES.NU,
    COUNTRIES.NZ,
    COUNTRIES.OM,
    COUNTRIES.PA,
    COUNTRIES.PE,
    COUNTRIES.PF,
    COUNTRIES.PG,
    COUNTRIES.PH,
    COUNTRIES.PK,
    COUNTRIES.PL,
    COUNTRIES.PM,
    COUNTRIES.PN,
    COUNTRIES.PR,
    COUNTRIES.PS,
    COUNTRIES.PT,
    COUNTRIES.PW,
    COUNTRIES.PY,
    COUNTRIES.QA,
    COUNTRIES.RE,
    COUNTRIES.RO,
    COUNTRIES.RU,
    COUNTRIES.RW,
    COUNTRIES.SA,
    COUNTRIES.SB,
    COUNTRIES.SC,
    COUNTRIES.SD,
    COUNTRIES.SE,
    COUNTRIES.SG,
    COUNTRIES.SH,
    COUNTRIES.SI,
    COUNTRIES.SJ,
    COUNTRIES.SK,
    COUNTRIES.SL,
    COUNTRIES.SM,
    COUNTRIES.SN,
    COUNTRIES.SO,
    COUNTRIES.SR,
    COUNTRIES.ST,
    COUNTRIES.SV,
    COUNTRIES.SY,
    COUNTRIES.SZ,
    COUNTRIES.TC,
    COUNTRIES.TD,
    COUNTRIES.TF,
    COUNTRIES.TG,
    COUNTRIES.TH,
    COUNTRIES.TJ,
    COUNTRIES.TK,
    COUNTRIES.TL,
    COUNTRIES.TM,
    COUNTRIES.TN,
    COUNTRIES.TO,
    COUNTRIES.TR,
    COUNTRIES.TT,
    COUNTRIES.TV,
    COUNTRIES.TW,
    COUNTRIES.TZ,
    COUNTRIES.UA,
    COUNTRIES.UG,
    COUNTRIES.UM,
    COUNTRIES.US,
    COUNTRIES.UY,
    COUNTRIES.UZ,
    COUNTRIES.VA,
    COUNTRIES.VC,
    COUNTRIES.VE,
    COUNTRIES.VG,
    COUNTRIES.VI,
    COUNTRIES.VN,
    COUNTRIES.VU,
    COUNTRIES.WF,
    COUNTRIES.WS,
    COUNTRIES.YE,
    COUNTRIES.YT,
    COUNTRIES.ZA,
    COUNTRIES.ZM,
    COUNTRIES.ZW,
];
export const COUNTRY_NAMES = {
    [COUNTRIES.AD]: "Andorra",
    [COUNTRIES.AE]: "United Arab Emirates",
    [COUNTRIES.AF]: "Afghanistan",
    [COUNTRIES.AG]: "Antigua and Barbuda",
    [COUNTRIES.AI]: "Anguilla",
    [COUNTRIES.AL]: "Albania",
    [COUNTRIES.AM]: "Armenia",
    [COUNTRIES.AN]: "Netherlands Antilles",
    [COUNTRIES.AO]: "Angola",
    [COUNTRIES.AQ]: "Antarctica",
    [COUNTRIES.AR]: "Argentina",
    [COUNTRIES.AS]: "American Samoa",
    [COUNTRIES.AT]: "Austria",
    [COUNTRIES.AU]: "Australia",
    [COUNTRIES.AW]: "Aruba",
    [COUNTRIES.AX]: "Åland Islands",
    [COUNTRIES.AZ]: "Azerbaijan",
    [COUNTRIES.BA]: "Bosnia and Herzegovina",
    [COUNTRIES.BB]: "Barbados",
    [COUNTRIES.BD]: "Bangladesh",
    [COUNTRIES.BE]: "Belgium",
    [COUNTRIES.BF]: "Burkina Faso",
    [COUNTRIES.BG]: "Bulgaria",
    [COUNTRIES.BH]: "Bahrain",
    [COUNTRIES.BI]: "Burundi",
    [COUNTRIES.BJ]: "Benin",
    [COUNTRIES.BM]: "Bermuda",
    [COUNTRIES.BN]: "Brunei Darussalam",
    [COUNTRIES.BO]: "Bolivia",
    [COUNTRIES.BR]: "Brazil",
    [COUNTRIES.BS]: "Bahamas",
    [COUNTRIES.BT]: "Bhutan",
    [COUNTRIES.BV]: "Bouvet Island",
    [COUNTRIES.BW]: "Botswana",
    [COUNTRIES.BY]: "Belarus",
    [COUNTRIES.BZ]: "Belize",
    [COUNTRIES.CA]: "Canada",
    [COUNTRIES.CC]: "Cocos (Keeling) Islands",
    [COUNTRIES.CD]: "Congo, The Democratic Republic of the",
    [COUNTRIES.CF]: "Central African Republic",
    [COUNTRIES.CG]: "Congo",
    [COUNTRIES.CH]: "Switzerland",
    [COUNTRIES.CI]: "Cote D'Ivoire",
    [COUNTRIES.CK]: "Cook Islands",
    [COUNTRIES.CL]: "Chile",
    [COUNTRIES.CM]: "Cameroon",
    [COUNTRIES.CN]: "China",
    [COUNTRIES.CO]: "Colombia",
    [COUNTRIES.CR]: "Costa Rica",
    [COUNTRIES.CS]: "Serbia and Montenegro",
    [COUNTRIES.CU]: "Cuba",
    [COUNTRIES.CV]: "Cape Verde",
    [COUNTRIES.CX]: "Christmas Island",
    [COUNTRIES.CY]: "Cyprus",
    [COUNTRIES.CZ]: "Czech Republic",
    [COUNTRIES.DE]: "Germany",
    [COUNTRIES.DJ]: "Djibouti",
    [COUNTRIES.DK]: "Denmark",
    [COUNTRIES.DM]: "Dominica",
    [COUNTRIES.DO]: "Dominican Republic",
    [COUNTRIES.DZ]: "Algeria",
    [COUNTRIES.EC]: "Ecuador",
    [COUNTRIES.EE]: "Estonia",
    [COUNTRIES.EG]: "Egypt",
    [COUNTRIES.EH]: "Western Sahara",
    [COUNTRIES.ER]: "Eritrea",
    [COUNTRIES.ES]: "Spain",
    [COUNTRIES.ET]: "Ethiopia",
    [COUNTRIES.FI]: "Finland",
    [COUNTRIES.FJ]: "Fiji",
    [COUNTRIES.FK]: "Falkland Islands (Malvinas)",
    [COUNTRIES.FM]: "Micronesia, Federated States of",
    [COUNTRIES.FO]: "Faroe Islands",
    [COUNTRIES.FR]: "France",
    [COUNTRIES.GA]: "Gabon",
    [COUNTRIES.GB]: "United Kingdom",
    [COUNTRIES.GD]: "Grenada",
    [COUNTRIES.GE]: "Georgia",
    [COUNTRIES.GF]: "French Guiana",
    [COUNTRIES.GG]: "Guernsey",
    [COUNTRIES.GH]: "Ghana",
    [COUNTRIES.GI]: "Gibraltar",
    [COUNTRIES.GL]: "Greenland",
    [COUNTRIES.GM]: "Gambia",
    [COUNTRIES.GN]: "Guinea",
    [COUNTRIES.GP]: "Guadeloupe",
    [COUNTRIES.GQ]: "Equatorial Guinea",
    [COUNTRIES.GR]: "Greece",
    [COUNTRIES.GS]: "South Georgia and the South Sandwich Islands",
    [COUNTRIES.GT]: "Guatemala",
    [COUNTRIES.GU]: "Guam",
    [COUNTRIES.GW]: "Guinea-Bissau",
    [COUNTRIES.GY]: "Guyana",
    [COUNTRIES.HK]: "Hong Kong",
    [COUNTRIES.HM]: "Heard Island and Mcdonald Islands",
    [COUNTRIES.HN]: "Honduras",
    [COUNTRIES.HR]: "Croatia",
    [COUNTRIES.HT]: "Haiti",
    [COUNTRIES.HU]: "Hungary",
    [COUNTRIES.ID]: "Indonesia",
    [COUNTRIES.IE]: "Ireland",
    [COUNTRIES.IL]: "Israel",
    [COUNTRIES.IM]: "Isle of Man",
    [COUNTRIES.IN]: "India",
    [COUNTRIES.IO]: "British Indian Ocean Territory",
    [COUNTRIES.IQ]: "Iraq",
    [COUNTRIES.IR]: "Iran, Islamic Republic Of",
    [COUNTRIES.IS]: "Iceland",
    [COUNTRIES.IT]: "Italy",
    [COUNTRIES.JE]: "Jersey",
    [COUNTRIES.JM]: "Jamaica",
    [COUNTRIES.JO]: "Jordan",
    [COUNTRIES.JP]: "Japan",
    [COUNTRIES.KE]: "Kenya",
    [COUNTRIES.KG]: "Kyrgyzstan",
    [COUNTRIES.KH]: "Cambodia",
    [COUNTRIES.KI]: "Kiribati",
    [COUNTRIES.KM]: "Comoros",
    [COUNTRIES.KN]: "Saint Kitts and Nevis",
    [COUNTRIES.KP]: "Korea, Democratic People'S Republic of",
    [COUNTRIES.KR]: "Korea, Republic of",
    [COUNTRIES.KW]: "Kuwait",
    [COUNTRIES.KY]: "Cayman Islands",
    [COUNTRIES.KZ]: "Kazakhstan",
    [COUNTRIES.LA]: "Lao People'S Democratic Republic",
    [COUNTRIES.LB]: "Lebanon",
    [COUNTRIES.LC]: "Saint Lucia",
    [COUNTRIES.LI]: "Liechtenstein",
    [COUNTRIES.LK]: "Sri Lanka",
    [COUNTRIES.LR]: "Liberia",
    [COUNTRIES.LS]: "Lesotho",
    [COUNTRIES.LT]: "Lithuania",
    [COUNTRIES.LU]: "Luxembourg",
    [COUNTRIES.LV]: "Latvia",
    [COUNTRIES.LY]: "Libyan Arab Jamahiriya",
    [COUNTRIES.MA]: "Morocco",
    [COUNTRIES.MC]: "Monaco",
    [COUNTRIES.MD]: "Moldova, Republic of",
    [COUNTRIES.MG]: "Madagascar",
    [COUNTRIES.MH]: "Marshall Islands",
    [COUNTRIES.MK]: "Macedonia, The Former Yugoslav Republic of",
    [COUNTRIES.ML]: "Mali",
    [COUNTRIES.MM]: "Myanmar",
    [COUNTRIES.MN]: "Mongolia",
    [COUNTRIES.MO]: "Macao",
    [COUNTRIES.MP]: "Northern Mariana Islands",
    [COUNTRIES.MQ]: "Martinique",
    [COUNTRIES.MR]: "Mauritania",
    [COUNTRIES.MS]: "Montserrat",
    [COUNTRIES.MT]: "Malta",
    [COUNTRIES.MU]: "Mauritius",
    [COUNTRIES.MV]: "Maldives",
    [COUNTRIES.MW]: "Malawi",
    [COUNTRIES.MX]: "Mexico",
    [COUNTRIES.MY]: "Malaysia",
    [COUNTRIES.MZ]: "Mozambique",
    [COUNTRIES.NA]: "Namibia",
    [COUNTRIES.NC]: "New Caledonia",
    [COUNTRIES.NE]: "Niger",
    [COUNTRIES.NF]: "Norfolk Island",
    [COUNTRIES.NG]: "Nigeria",
    [COUNTRIES.NI]: "Nicaragua",
    [COUNTRIES.NL]: "Netherlands",
    [COUNTRIES.NO]: "Norway",
    [COUNTRIES.NP]: "Nepal",
    [COUNTRIES.NR]: "Nauru",
    [COUNTRIES.NU]: "Niue",
    [COUNTRIES.NZ]: "New Zealand",
    [COUNTRIES.OM]: "Oman",
    [COUNTRIES.PA]: "Panama",
    [COUNTRIES.PE]: "Peru",
    [COUNTRIES.PF]: "French Polynesia",
    [COUNTRIES.PG]: "Papua New Guinea",
    [COUNTRIES.PH]: "Philippines",
    [COUNTRIES.PK]: "Pakistan",
    [COUNTRIES.PL]: "Poland",
    [COUNTRIES.PM]: "Saint Pierre and Miquelon",
    [COUNTRIES.PN]: "Pitcairn",
    [COUNTRIES.PR]: "Puerto Rico",
    [COUNTRIES.PS]: "Palestinian Territory, Occupied",
    [COUNTRIES.PT]: "Portugal",
    [COUNTRIES.PW]: "Palau",
    [COUNTRIES.PY]: "Paraguay",
    [COUNTRIES.QA]: "Qatar",
    [COUNTRIES.RE]: "Reunion",
    [COUNTRIES.RO]: "Romania",
    [COUNTRIES.RU]: "Russian Federation",
    [COUNTRIES.RW]: "RWANDA",
    [COUNTRIES.SA]: "Saudi Arabia",
    [COUNTRIES.SB]: "Solomon Islands",
    [COUNTRIES.SC]: "Seychelles",
    [COUNTRIES.SD]: "Sudan",
    [COUNTRIES.SE]: "Sweden",
    [COUNTRIES.SG]: "Singapore",
    [COUNTRIES.SH]: "Saint Helena",
    [COUNTRIES.SI]: "Slovenia",
    [COUNTRIES.SJ]: "Svalbard and Jan Mayen",
    [COUNTRIES.SK]: "Slovakia",
    [COUNTRIES.SL]: "Sierra Leone",
    [COUNTRIES.SM]: "San Marino",
    [COUNTRIES.SN]: "Senegal",
    [COUNTRIES.SO]: "Somalia",
    [COUNTRIES.SR]: "Suriname",
    [COUNTRIES.ST]: "Sao Tome and Principe",
    [COUNTRIES.SV]: "El Salvador",
    [COUNTRIES.SY]: "Syrian Arab Republic",
    [COUNTRIES.SZ]: "Swaziland",
    [COUNTRIES.TC]: "Turks and Caicos Islands",
    [COUNTRIES.TD]: "Chad",
    [COUNTRIES.TF]: "French Southern Territories",
    [COUNTRIES.TG]: "Togo",
    [COUNTRIES.TH]: "Thailand",
    [COUNTRIES.TJ]: "Tajikistan",
    [COUNTRIES.TK]: "Tokelau",
    [COUNTRIES.TL]: "Timor-Leste",
    [COUNTRIES.TM]: "Turkmenistan",
    [COUNTRIES.TN]: "Tunisia",
    [COUNTRIES.TO]: "Tonga",
    [COUNTRIES.TR]: "Turkey",
    [COUNTRIES.TT]: "Trinidad and Tobago",
    [COUNTRIES.TV]: "Tuvalu",
    [COUNTRIES.TW]: "Taiwan, Province of China",
    [COUNTRIES.TZ]: "Tanzania, United Republic of",
    [COUNTRIES.UA]: "Ukraine",
    [COUNTRIES.UG]: "Uganda",
    [COUNTRIES.UM]: "United States Minor Outlying Islands",
    [COUNTRIES.US]: "United States",
    [COUNTRIES.UY]: "Uruguay",
    [COUNTRIES.UZ]: "Uzbekistan",
    [COUNTRIES.VA]: "Holy See (Vatican City State)",
    [COUNTRIES.VC]: "Saint Vincent and the Grenadines",
    [COUNTRIES.VE]: "Venezuela",
    [COUNTRIES.VG]: "Virgin Islands, British",
    [COUNTRIES.VI]: "Virgin Islands, U.S.",
    [COUNTRIES.VN]: "Viet Nam",
    [COUNTRIES.VU]: "Vanuatu",
    [COUNTRIES.WF]: "Wallis and Futuna",
    [COUNTRIES.WS]: "Samoa",
    [COUNTRIES.YE]: "Yemen",
    [COUNTRIES.YT]: "Mayotte",
    [COUNTRIES.ZA]: "South Africa",
    [COUNTRIES.ZM]: "Zambia",
    [COUNTRIES.ZW]: "Zimbabwe",
};
export const COUNTRY_TAX_MAP = [
    {
        country: "AD",
        type: "ad_nrt",
        description: "Andorran NRT number",
        regex: /^A-[0-9]{6}-[A-Z]$/,
        example: "A-123456-Z",
    },
    {
        country: "AE",
        type: "ae_trn",
        description: "United Arab Emirates TRN",
        regex: /^[0-9]{15}$/,
        example: "123456789012345",
    },
    {
        country: "AR",
        type: "ar_cuit",
        description: "Argentinian tax ID number",
        regex: /^[0-9]{2}-[0-9]{7}-[0-9]{2}$/,
        example: "12-3456789-01",
    },
    {
        country: "AU",
        type: "au_abn",
        description: "Australian Business Number (AU ABN)",
        regex: /^[0-9]{11}$/,
        example: "12345678912",
    },
    {
        country: "AU",
        type: "au_arn",
        description: "Australian Taxation Office Reference Number",
        regex: /^[0-9]{12}$/,
        example: "123456789123",
    },
    {
        country: "BE",
        type: "eu_vat",
        description: "European VAT number",
        regex: /^BE[0-9]{10}$/,
        example: "BE0123456789",
    },
    {
        country: "BG",
        type: "bg_uic",
        description: "Bulgaria Unified Identification Code",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "BG",
        type: "eu_vat",
        description: "Bulgaria - European VAT number",
        regex: /^BG[0-9]{9,10}$/, // Prefix with zero ‘0’ if the customer provides a 9 digit VAT number
        example: "BG1234567890",
    },
    {
        country: "BO",
        type: "bo_tin",
        description: "Bolivian tax ID",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "BR",
        type: "br_cnpj",
        description: "Brazilian CNPJ number",
        regex: /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
        example: "01.234.456/5432-10",
    },
    {
        country: "BR",
        type: "br_cpf",
        description: "Brazilian CPF number",
        regex: /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
        example: "123.456.789-87",
    },
    {
        country: "CA",
        type: "ca_bn",
        description: "Canadian BN",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "CA",
        type: "ca_gst_hst",
        description: "Canadian GST/HST number",
        regex: /^[0-9]{9}RT[0-9]{4}$/,
        example: "123456789RT0002",
    },
    {
        country: "CA",
        type: "ca_pst_bc",
        description: "Canadian PST number (British Columbia)",
        regex: /^PST-[0-9]{4}-[0-9]{4}$/,
        example: "PST-1234-5678",
    },
    {
        country: "CA",
        type: "ca_pst_mb",
        description: "Canadian PST number (Manitoba)",
        regex: /^[0-9]{6}-[0-9]{1}$/,
        example: "123456-7",
    },
    {
        country: "CA",
        type: "ca_pst_sk",
        description: "Canadian PST number (Saskatchewan)",
        regex: /^[0-9]{7}$/,
        example: "1234567",
    },
    {
        country: "CA",
        type: "ca_qst",
        description: "Canadian QST number (Québec)",
        regex: /^[0-9]{10}TQ[0-9]{4}$/,
        example: "1234567890TQ1234",
    },
    {
        country: "CL",
        type: "cl_tin",
        description: "Chilean TIN",
        regex: /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}-K$/,
        example: "12.345.678-K",
    },
    {
        country: "CN",
        type: "cn_tin",
        description: "Chinese tax ID",
        regex: /^[0-9]{18}$/,
        example: "123456789012345678",
    },
    {
        country: "CO",
        type: "co_nit",
        description: "Colombian NIT number",
        regex: /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{1}$/,
        example: "123.456.789-0",
    },
    {
        country: "CR",
        type: "cr_tin",
        description: "Costa Rican tax ID",
        regex: /^[0-9]{1}-[0-9]{3}-[0-9]{6}$/,
        example: "1-234-567890",
    },
    {
        country: "DO",
        type: "do_rcn",
        description: "Dominican RCN number",
        regex: /^[0-9]{3}-[0-9]{7}-[0-9]{1}$/,
        example: "123-4567890-1",
    },
    {
        country: "EC",
        type: "ec_ruc",
        description: "Ecuadorian RUC number",
        regex: /^[0-9]{13}$/,
        example: "1234567890001",
    },
    {
        country: "EG",
        type: "eg_tin",
        description: "Egyptian Tax Identification Number",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "EU",
        type: "eu_oss_vat",
        description: "European One Stop Shop VAT number for non-Union scheme",
        regex: /^EU[0-9]{9}$/,
        example: "EU123456789",
    },
    {
        country: "HR",
        type: "eu_vat",
        description: "Croatia - European VAT number",
        regex: /^HR[0-9]{11}$/,
        example: "HR12345678912",
    },
    {
        country: "AT",
        type: "eu_vat",
        description: "Austria - European VAT number",
        regex: /^ATU[0-9]{7,9}$/,
        example: "ATU12345678",
    },
    {
        country: "CY",
        type: "eu_vat",
        description: "Cyprus - European VAT number",
        regex: /^CY[0-9]{8}[A-Z]$/,
        example: "CY12345678Z",
    },
    {
        country: "CZ",
        type: "eu_vat",
        description: "Czech Republic - European VAT number",
        regex: /^CZ[0-9]{8,10}$/,
        example: "CZ1234567890",
    },
    {
        country: "DK",
        type: "eu_vat",
        description: "Denmark - European VAT number",
        regex: /^DK[0-9]{8}$/,
        example: "DK12345678",
    },
    {
        country: "EE",
        type: "eu_vat",
        description: "Estonia - European VAT number",
        regex: /^EE[0-9]{9}$/,
        example: "EE123456789",
    },
    {
        country: "FI",
        type: "eu_vat",
        description: "Finland - European VAT number",
        regex: /^FI[0-9]{8}$/,
        example: "FI12345678",
    },
    {
        country: "FR",
        type: "eu_vat",
        description: "France - European VAT number",
        regex: /^FR[A-Z0-9]{9,11}$/, // made more forgiving - 11 characters May include alphabetical characters (any except O or I) as first or second or first and second characters. - FR + 12345678901 X1234567890 1X123456789 XX123456789
        example: "FRAB123456789",
    },
    {
        country: "DE",
        type: "eu_vat",
        description: "Germany - European VAT number",
        regex: /^DE[0-9]{9}$/,
        example: "DE123456789",
    },
    {
        country: "GR",
        type: "eu_vat",
        description: "Greece - European VAT number",
        regex: /^EL[0-9]{9}$/,
        example: "EL123456789",
    },
    {
        country: "HK",
        type: "hk_br",
        description: "Hong Kong BR number",
        regex: /^[0-9]{8}$/,
        example: "12345678",
    },
    {
        country: "HU",
        type: "eu_vat",
        description: "Hungary - European VAT number",
        regex: /^HU[0-9]{8,11}$/,
        example: "HU12345678",
    },
    {
        country: "IN",
        type: "in_gst",
        description: "Indian GST number",
        regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z1-9]{4}$/,
        example: "12ABCDE3456FGZH",
    },
    {
        country: "ID",
        type: "id_npwp",
        description: "Indonesian NPWP number",
        regex: /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]{1}-[0-9]{3}\.[0-9]{3}$/,
        example: "12.345.678.9-012.345",
    },
    {
        country: "IE",
        type: "eu_vat",
        description: "Ireland - European VAT number",
        regex: /^IE[0-9]{7}[A-Z]{1,2}$/, // 8 or 9 characters Includes one or two alphabetical characters (last, or second and last, or last 2) - 1234567WA (companies) 1234567FA (individuals)
        example: "IE1234567WA",
    },
    {
        country: "IL",
        type: "il_vat",
        description: "Israel VAT",
        regex: /^[0-9]{9}$/,
        example: "000012345",
    },
    {
        country: "IT",
        type: "eu_vat",
        description: "Italy - European VAT number",
        regex: /^IT[0-9]{11}$/,
        example: "IT12345678912",
    },
    {
        country: "JP",
        type: "jp_cn",
        description: "Japanese Corporate Number (*Hōjin Bangō*)",
        regex: /^[0-9]{13}$/,
        example: "1234567891234",
    },
    {
        country: "JP",
        type: "jp_rn",
        description: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
        regex: /^[0-9]{5}$/,
        example: "12345",
    },
    {
        country: "KR",
        type: "kr_brn",
        description: "Korean BRN",
        regex: /^[0-9]{3}-[0-9]{2}-[0-9]{5}$/,
        example: "123-45-67890",
    },
    {
        country: "LV",
        type: "eu_vat",
        description: "Latvia - European VAT number",
        regex: /^LV[0-9]{11}$/,
        example: "LV12345678912",
    },
    {
        country: "LI",
        type: "li_uid",
        description: "Liechtensteinian UID number",
        regex: /^CHE[0-9]{9}$/,
        example: "CHE123456789",
    },
    {
        country: "LT",
        type: "eu_vat",
        description: "Lithuania - European VAT number",
        regex: /^LT[0-9]{9,12}$/, // 9 or 12 characters
        example: "LT123456789123",
    },
    {
        country: "LU",
        type: "eu_vat",
        description: "Luxembourg - European VAT number",
        regex: /^LU[0-9]{8}$/,
        example: "LU12345678",
    },
    {
        country: "MY",
        type: "my_frp",
        description: "Malaysian FRP number",
        regex: /^[0-9]{8}$/,
        example: "12345678",
    },
    {
        country: "MY",
        type: "my_itn",
        description: "Malaysian ITN",
        regex: /^C(\s)?[0-9]{10}$/,
        example: "C 1234567890",
    },
    {
        country: "MY",
        type: "my_sst",
        description: "Malaysian SST number",
        regex: /^A[0-9]{2}-[0-9]{4}-[0-9]{8}$/,
        example: "A12-3456-78912345",
    },
    {
        country: "MT",
        type: "eu_vat",
        description: "Malta - European VAT number",
        regex: /^MT[0-9]{8}$/,
        example: "MT12345678",
    },
    {
        country: "MX",
        type: "mx_rfc",
        description: "Mexican RFC number",
        // https://learn.sayari.com/mexico-tax-id-number-rfc/
        regex: /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/,
        example: "ABC010203AB9",
    },
    {
        country: "NL",
        type: "eu_vat",
        description: "Netherlands - European VAT number",
        regex: /^NL[0-9]{9}B[0-9]{2}$/, // 12 characters. The 10th character is always B, Companies forming a VAT Group have the suffix BO2
        example: "NL123456789B12",
    },
    {
        country: "NZ",
        type: "nz_gst",
        description: "New Zealand GST number",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "NO",
        type: "no_vat",
        description: "Norwegian VAT number",
        regex: /^[0-9]{9}MVA$/, // 9 digits and the letters 'MVA' to indicate VAT registration, Last (ninth) digit is a MOD11 checksum digit.[20]
        example: "123456789MVA",
    },
    {
        country: "PL",
        type: "eu_vat",
        description: "Poland - European VAT number",
        regex: /^PL[0-9]{10}$/,
        example: "PL1234567890",
    },
    {
        country: "PT",
        type: "eu_vat",
        description: "Portugal - European VAT number",
        regex: /^PT[0-9]{9}$/,
        example: "PT123456789",
    },
    {
        country: "RO",
        type: "eu_vat",
        description: "Romania - European VAT number",
        regex: /^RO[0-9]+$/,
        example: "RO1234567891",
    },
    {
        country: "RU",
        type: "ru_inn",
        description: "Russian INN",
        regex: /^[0-9]{10,12}$/, // INN (ИНН) 10 digit code - legal entities, 12 digit code - people
        example: "1234567891",
    },
    {
        country: "RU",
        type: "ru_kpp",
        description: "Russian KPP",
        regex: /^[0-9]{9,13}$/, // OGRN (ОГРН)	9999999999999	13 digit code - people and legal entities
        example: "123456789",
    },
    {
        country: "SA",
        type: "sa_vat",
        description: "Saudi Arabia VAT",
        regex: /^[0-9]{15}$/,
        example: "123456789012345",
    },
    {
        country: "SG",
        type: "sg_gst",
        description: "Singaporean GST",
        regex: /^[a-zA-Z][0-9]{8}[a-zA-Z]$/,
        example: "M12345678X",
    },
    {
        country: "SG",
        type: "sg_uen",
        description: "Singaporean UEN",
        regex: /^[0-9]{9}[a-zA-Z]$/,
        example: "123456789F",
    },
    {
        country: "SK",
        type: "eu_vat",
        description: "Slovakia - European VAT number",
        regex: /^SK[0-9]{10}$/,
        example: "SK1234567891",
    },
    {
        country: "SI",
        type: "eu_vat",
        description: "Slovenia - European VAT number",
        regex: /^SI[0-9]{8}$/,
        example: "SI12345678",
    },
    {
        country: "ZA",
        type: "za_vat",
        description: "South African VAT number",
        regex: /^4[0-9]{9}$/,
        example: "4123456789",
    },
    {
        country: "ES",
        type: "es_cif",
        description: "Spanish CIF number",
        // https://www.avalara.com/vatlive/en/eu-vat-rules/eu-vat-number-registration/eu-vat-number-formats.html
        // X12345678 or 12345678X or X1234567X (X stands for any letter)
        regex: /^([A-Z][0-9]{8}|[A-Z][0-9]{7}[A-Z]|[0-9]{8}[A-Z])$/,
        example: "A12345678",
    },
    {
        country: "ES",
        type: "eu_vat",
        description: "Spain - European VAT number",
        regex: /^ES[A-Z0-9]{9}$/, // made it more forgiving - 9 characters Includes 1 or 2 alphabetical characters (first or last or first and last)
        example: "ESA1234567Z",
    },
    {
        country: "SE",
        type: "eu_vat",
        description: "Sweden - European VAT number",
        regex: /^SE[0-9]{12}$/,
        example: "SE123456789123",
    },
    {
        country: "CH",
        type: "ch_vat",
        description: "Switzerland - European VAT number",
        regex: /^CHE-[0-9]{3}.[0-9]{3}.[0-9]{3}(\s)(MWST|TVA|IVA)$/,
        example: "CHE-123.456.789 MWST",
    },
    {
        country: "TW",
        type: "tw_vat",
        description: "Taiwanese VAT",
        regex: /^[0-9]{8}$/,
        example: "12345678",
    },
    {
        country: "TH",
        type: "th_vat",
        description: "Thai VAT",
        regex: /^[0-9]{13}$/,
        example: "1234567891234",
    },
    {
        country: "GB",
        type: "gb_vat",
        description: "United Kingdom VAT number",
        regex: /^GB[0-9]{9}$/,
        example: "GB123456789",
    },
    {
        country: "GE",
        type: "ge_vat",
        description: "Georgian VAT",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "GB",
        type: "eu_vat",
        description: "Northern Ireland VAT number",
        regex: /^XI[0-9]{9}$/,
        example: "XI123456789",
    },
    {
        country: "HU",
        type: "hu_tin",
        description: "Hungary tax number (adószám)",
        regex: /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/,
        example: "12345678-1-23",
    },
    {
        country: "IS",
        type: "is_vat",
        description: "Icelandic VAT",
        regex: /^[0-9]{6}$/,
        example: "123456",
    },
    {
        country: "JP",
        type: "jp_trn",
        description: "Japanese Tax Registration Number (*Tōroku Bangō*)",
        regex: /^T[0-9]{13}$/,
        example: "T1234567891234",
    },
    {
        country: "KE",
        type: "ke_pin",
        description: "Kenya Revenue Authority Personal Identification Number",
        regex: /^P[0-9]{3}[0-9]{6}[A-Z]{1}$/,
        example: "P000111111A",
    },
    {
        country: "PE",
        type: "pe_ruc",
        description: "Peruvian RUC number",
        regex: /^[0-9]{11}$/,
        example: "12345678901",
    },
    {
        country: "PH",
        type: "ph_tin",
        description: "Philippines Tax Identification Number",
        regex: /^[0-9]{12}$/,
        example: "123456789012",
    },
    {
        country: "RO",
        type: "ro_tin",
        description: "Romanian tax ID number",
        regex: /^[0-9]{13}$/,
        example: "1234567890123",
    },
    {
        country: "RS",
        type: "rs_pib",
        description: "Serbian PIB number",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "SI",
        type: "si_tin",
        description: "Slovenia tax number (davčna številka)",
        regex: /^[0-9]{8}$/,
        example: "12345678",
    },
    {
        country: "SV",
        type: "sv_nit",
        description: "El Salvadorian NIT number",
        regex: /^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]$/,
        example: "1234-567890-123-4",
    },
    {
        country: "TR",
        type: "tr_tin",
        description: "Turkish Tax Identification Number",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "UA",
        type: "ua_vat",
        description: "Ukrainian VAT",
        regex: /^[0-9]{9}$/,
        example: "123456789",
    },
    {
        country: "UY",
        type: "uy_ruc",
        description: "Uruguayan RUC number",
        regex: /^[0-9]{12}$/,
        example: "123456789012",
    },
    {
        country: "VE",
        type: "ve_rif",
        description: "Venezuelan RIF number",
        regex: /^[E|I|J|V]-[0-9]{8}-[0-9]{1}$/,
        example: "E-12345678-9",
    },
    {
        country: "VN",
        type: "vn_tin",
        description: "Vietnamese tax ID number",
        regex: /^[0-9]{10}$/,
        example: "1234567890",
    },
    {
        country: "US",
        type: "us_ein",
        description: "United States EIN",
        regex: /^[0-9]{2}-[0-9]{7}$/,
        example: "12-3456789",
    },
];
